.user-bar {
  z-index: 1000;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);

  .user-count {
    span {
      padding-left: 22px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone)
    };
    flex: 1;
  }

  .user-buttons-container {
    display: flex;
    height: 100%;
    .custom-button {
      padding-left: 32px;
      padding-right: 39px;
      background-color: var(--color-medium-blue);
      text-transform: none;
      border: none;
      border-radius: 0;
      box-shadow: none;

      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;

      span {
        padding-right: 15px;
      }
      .MuiButton-startIcon {
        svg {
          font-size: 22px;
        }
      }
    }

    .search-input {
      width: 300px;
    }
  }
}