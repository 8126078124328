.error-container {
  flex-direction: column;
  max-height: 15vh;
  margin-bottom: 32px;
  overflow: auto;

  .error-full-message {
    display: flex;
    margin-bottom: 10px;
    .username-error {
      color: var(--color-red-600);
      width: 100%;
      padding-left: 18px;
    
      .error-list {
        color: var(--color-red-600);
        font-weight: 600;
      }
  
      .error-message::before {
        content: '\a';
        white-space: pre;
      }
    }
  }
 
}