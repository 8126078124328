.admin-chip {
  width: fit-content;
  margin-right: 10px;
  height: 25px;
  background: #FDF6DA !important;
  border-radius: 24px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-carbone)
}

.member-chip {
  width: fit-content;
  height: 25px;
  background: #E0F9E1 !important;
  border-radius: 24px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--color-carbone)
}