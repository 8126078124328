.help-container {
  height: 100%;
  background-color: var(--color-white);
  .help-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    background-color: #F8F9FB;
  
  
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 100%;
    color: var(--color-carbone);
  
  }
  
  .help-main {
    display: flex;
    overflow: auto;
    height: calc(100% - 90px);
    flex-direction: column;
    align-items: center;
    padding-top: 32px;

    .accordion {
      margin-bottom: 16px;
      &:before {
        display: none;
      }
      border: none;
      width: 40%;
      border-radius: 8px;
      background-color: #F8F9FB;
  
      .accordion-title {
        padding: 24px;
        height: 76px;
        font-weight: 900;
        font-size: 16px;
        color: var(--color-carbone);
        svg {
          font-size: 28px;
          color: var(--color-carbone);
        }
      }
      &.Mui-expanded:last-of-type {
        margin-bottom: 16px;
      }
    }
    .accordion-summary {
      padding: 8px 24px 24px 24px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: var(--color-carbone);
      background-color: var(--color-white);
      white-space: pre-line;
      
    }
  
  }
}