.details-bar {
  height: 67px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .group-menu {
    height: inherit;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F8F9FB;
    border-right: 1px solid var(--color-grey-100);
  }
  .details-group {
    display: flex;
    align-items: center;
    .name {
      padding-left: 22px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone)
    }
    .count {
      padding-left: 10px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone)
    }

    .group-info-perm {
      outline: none;
      svg {
        font-size: 20px;
      }

    }
  }

  .usergroup-close {
    padding: 22px 24px;
    button {
      outline: none;
    }
  }

  .buttons-container {
    display: flex;
    flex: 1;
    height: 100%;
    .search-input {
      flex: 1;
      border-left: 0;
    }
    .custom-button {
      flex: 0.6;
      padding-left: 32px;
      padding-right: 39px;
      background-color: var(--color-medium-blue);
      text-transform: none;
      border: none;
      border-radius: 0;
      box-shadow: none;

      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;

      span {
        padding-right: 15px;
      }
      .MuiButton-startIcon {
        svg {
          font-size: 22px;
        }
      }
    }

    .autocomplete {
      flex: 1;
      .textfield-autocomplete {
        height: 100%;
      }
      .MuiInput-root {
        height: 100%;
        border: none !important;
        flex: 1;
        border: 1px solid var(--color-grey-100);
        border-bottom: none;
        padding-left: 25px;
        padding-right: 22px;
        padding-bottom: 0;
      }
      input {
        padding-left: 20px;
      }
    }
  }


}