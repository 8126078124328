.usergroup-bar {
  z-index: 1000;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-grey-100);

  .usergroup-breadcrumbs {
    padding-left: 27px;
    flex: 1;
    .home-button {
      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone);
      opacity: 0.3;
    }

    .group-breadcrumb {
      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone);
    }
  }

  .usergroup-close {
    padding: 22px 24px;
    button {
      outline: none;
    }
  }
}