.search-input {
  flex: 1;
  border: 1px solid var(--color-grey-100);
  border-bottom: none;
  padding-left: 25px;

  input {
    padding-left: 20px;
  }
}
