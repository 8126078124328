.header-modal {
  height: 5% !important;
}
.main-modal-alternative {
  .field-container {
    margin-bottom: 34px;
    .alternative-username-textfield {
      height: 56px;
      width: 100%;
      padding: 16px;
      background: #F1F5FB;
      border: 1px solid rgba(191, 191, 194, 0.1);
      border-radius: 4px;
    }
    
    .alternative-textfield-label {
      padding-bottom: 14px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      align-items: center;
      color: #8A8A8A;
    }
    
  }

  .noUsername-container {
    background: red !important;
  }
  .button-back {
    font-weight: 600 !important;
  }

  .Mui-error {
    border-color: red !important;
  }

  .error-message {
    position: absolute;
    font-size: 12px;
    color: red;
  }
}