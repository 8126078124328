.typeChip {
  width: fit-content;
  height: 25px !important;
  border-radius: 24px !important;
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-carbone) !important;
  text-transform: capitalize;
}