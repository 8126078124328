.info-container {
  max-height: 15vh;
  margin-bottom: 32px;
  overflow: auto;

  .username-creation-info {
    color: #546EFE;
    width: 100%;
    padding-left: 18px;
  
    .username-info-list {
      color: #546EFE;
      font-weight: 600;
    }
  }
}