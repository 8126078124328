.permission-button {
  width: 85px;
  height: 35px;
  border: 1px solid var(--color-medium-blue);;
  border-radius: 4px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-medium-blue);
  text-transform: none !important;
}