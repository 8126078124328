.token-bar {
  z-index: 1000;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--color-white);

  .token-count {
    span {
      padding-left: 22px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone)
    };
  }

  .token-buttons-container {
    display: flex;

    height: 100%;
    .search-input {
      width: 300px;
    }

  }
}