.token-modal {
  border: 1px solid #256EFF;
  padding: 12px 32px 16px 32px;
  width: 50%;
  height: 60%;
  border-radius: 4px;
  overflow: auto;

  .row {
    margin-right: 0px;
    margin-left: 0px; 
  }
  
  .header-modal {
    padding-bottom: 32px;
    .title {
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
      letter-spacing: 0.01em;
    }
    .close-button {
      color: var(--color-carbone);
      outline: none;
    }
  }

  .token-error {
    color: var(--color-red-600);
    width: 100%;
    padding-left: 10px;
  }

  .token-info-container {
    width: 100%;
    flex: 1;
    margin-bottom: 20px;

    .role-text {
      overflow: auto;
    }
  }
  .table-container {

    height: calc(100% - (var(--userbar-height)));
    width: 100vw;
    
    #info-panel {
      .container {
        width: 35% !important;
      }
    }
    
    .MuiTableContainer-root {  
      overflow-y: auto;
      overflow-x: auto;
      height: 100%;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: var(--color-grey-100);
      .MuiTableHead-root {
        height: 56px;
        background: var(--color-white);
        
        .MuiTableCell-root {
          
          border-width: 0px 1px 1px 0px;
          border-style: solid;
          border-color: var(--color-grey-100);
          padding: 21px 16px;    
          font-family: Avenir;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: var(--color-carbone);
        }

        .MuiTableCell-root:last-of-type {
          border-right: none
        }
      }
    
      .MuiTableBody-root {
        border: 1px solid red !important;
        .MuiTableRow-root {
          height: 56px;
          border-left: 1px solid red;
          .MuiTableCell-root {
            height: 56px;
            padding-top: 0;
            padding-bottom: 0;
            font-family: Avenir;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: var(--color-carbone);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: none;
          }
        }
      }
    }
  }
}