.copy-container {
  width: 100%;
  padding-left: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .copy-full-test {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
  }
}

.copy-container-hover {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #E2E7EF;
  opacity: 0.8;
  height: 38px;
  border-radius: 4px;

  .copy-reduced-text {
    padding-left: 12px;
    color: #C2C2C2;
    font-weight: 400;
    font-family: Avenir;
    font-style: normal;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 80%;
  }
  
  .copy-button {
    outline: none;
    text-transform: none;
    font-family: Avenir;
    font-style: normal;
    color: #546EFE;
    font-weight: 700;
  }
}