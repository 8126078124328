.manage-users-menu {

  border: 1px solid #E2E7EF;
  border-radius: 4px;

  li:hover {
    background-color: #F8F9FB;
  }
  li {
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: var(--color-grey-900);
    height: 40px;
  }

}