.user-creation-modal {
  border: 1px solid #256EFF;
  
  @media (min-height: 400px) {
    width: 40%;
    height: 60%;
    max-height: 80%;
    padding: 12px 32px 16px 32px;
  }

  @media (min-height: 900px) {
    width: 35%;
    height: 50%;
    max-height: 80%;
    padding: 24px 32px 32px 32px;
  }

  border-radius: 4px;

  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .header-modal {
    padding-bottom: 32px;
    .title {
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
      letter-spacing: 0.01em;
    }
    .close-button {
      color: var(--color-carbone);
      outline: none;
    }
  }

  .main-modal {
    margin-bottom: 85px;
    height: 25vh;
    .textfield-label {
      font-family: Avenir;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      padding-bottom: 12px;
    }
    .username-textfield {
      background-color: var(--color-white);
      border: 1px solid #D1DCED;
      border-radius: 4px;
      padding: 16px 16px 16px 12px;

      &.Mui-focused {
        border: 1px solid var(--color-medium-blue);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08), -2px -2px 7px rgba(0, 0, 0, 0.06);
      }
    }
  }

  .footer-modal {
 
    .noUsername-container {
      padding-bottom: 16px;

      .button-no-username {
        height: 21px;
        padding-bottom: 0;
        outline: none;
        text-transform: none;
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        text-decoration-line: underline;
        color: var(--color-carbone);
      }
    }

    .button-add {
      height: 56px;
      flex: 1;
      text-transform: none;
      border: 2px solid var(--color-medium-blue);
      border-radius: 30px;    
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: var(--color-medium-blue);

      &.Mui-disabled {
        opacity: 0.3;
      }
    }

    .button-create {
      height: 56px;
      flex: 1;
      text-transform: none;
      background-color:var(--color-medium-blue);
      border-radius: 30px;    
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #F8F8F8;

      &.Mui-disabled {
        opacity: 0.3;
      }
    }
  }

}

.backdrop {
  z-index: 1000;
  position: absolute !important;
  background-color: #ffffffe8 !important;
  color: #9AA6E4;
}