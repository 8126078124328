.group-container {
  width: 100%;
  height: calc(100% - 68px);
  overflow-y: auto;
  padding-left: 24px;
  border-right: 1px solid var(--color-grey-100);
  .group-title-text span {
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 100%;
    color: var(--color-carbone);
  }
  
  .group-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: var(--color-carbone);
  }

  .group-list-item {
    height: 26px;
    padding-bottom: 6px;
  }

  .expand-icon {
    margin-left: 8px;
    color: var(--color-carbone);
    svg {
      font-size: 24px;
    }
  }
}